h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #000;
}

h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #000;
}

h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #000;
}
